const brief_config = {
	direction: 'row',
	img: require('../../../assets/ke-img/Home/employers-bg.webp'),
	title: 'Benefits to Employers',
	ul_msg: [
		'Offer a no cost, high-value employee benefit that makes a real difference to staff and differentiates your employer brand.',
		'Support talent attraction and retention with a market-leading employee value proposition that includes on-demand pay.',
		'Reduce the time it takes for employees to receive pay with card rail payments.',
		'Automate salary advance requests, eliminating the manual process and reducing costs.',
		'Create caps and tolerances to help employees make best use of the tool.'
	],
	link: 'https://play.google.com/store/apps/details?id=tifislice.loan.credit.cash.longterm&referrer=af_tranid%3Db6929S35Vu07MSmvqWOwOA%26shortlink%3Dloan%26pid%3DSMS%26c%3D%E8%90%A5%E9%94%80%E7%94%A8%E6%88%B7%E6%B3%A8%E5%86%8C%26af_cost_value%3D0.0031%26af_cost_currency%3DUSD'
}

export const employer_config = {
	brief_config,
	first_img: require('../../../assets/ke-img/Employers/first-bg.webp'),
	h5_first_img: require('../../../assets/ke-img/Employers/h5-first-bg.webp'),
	title: 'TiFi for containers',
	list_msg: [
		'In current world  many people face increased pressures on their money, and often need quick access to their earnings in order to meet their financial obligations. The ability for Earned Wage Access (EWA) to address these demands explains why it’s gaining traction in many businesses around the world.',
		'Earned Wage Access represents a major shift in how employees are paid for the work they do. Instead of receiving their pay at pre-agreed, fixed intervals - normally the end of the month or the end of the week - employees accrue pay on a daily basis, in much the same way as they accrue paid leave entitlement. Then, at any time they wish, employees can withdraw upto an agreed percentage amount of the pay they have accrued, giving them unprecedented flexibility and control over their personal finances.',
		'But how does Earned Wage Access work in practice? And how suitable is it for your business?'
	]
}
