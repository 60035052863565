<template>
	<div>
		<emploer-and-emploee :config="employer_config"></emploer-and-emploee>
	</div>
</template>

<script>
import EmploerAndEmploee from '../Components/emploer_and_emploee.vue'
import { employer_config } from './employer.config'
export default {
	components: {
		EmploerAndEmploee
	},
	data() {
		return {
			employer_config
		}
	}
}
</script>

<style scoped lang="scss"></style>
